<template>
  <div>
    <UIExit label="Exit Control Panel" :exit-url="defaultRedirect" />
    <router-view></router-view>
  </div>
</template>

<script>
import ROUTE from '@/src/constants/route-constants.js'
import { mapActions, mapGetters } from 'vuex'
import layoutConstants from '@src/constants/layout-constant.js'
import { SCOPE_LEVELS } from '@src/constants/general-constants.js'
import UIExit from '@src/components/UiElements/UIExit.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
export default {
  name: 'ControlPanelLayout',
  components: {
    UIExit,
  },
  beforeRouteUpdate(to, from, next) {
    this.modifyLeftBarDropdown(to.name)
    next()
  },
  page: {
    title: ' Control Panel',
    meta: [
      {
        name: 'description',
        content: ' Control Panel',
      },
    ],
  },
  data() {
    return {
      defaultRedirect: ROUTE.QUICK_ACTIONS.name,
    }
  },
  computed: {
    ...mapGetters('auth', ['isOwner']),
  },
  created() {
    this.setLeftbarContent({
      back: '',
      customeHeader: 'left-bar-class-list',
      defaultRoute: 'control-panel-dashboard',
      SHOW_SECTION_DROPDOWN_TO: layoutConstants.showSectionDropdownTo,
      SHOW_CLASS_DROPDOWN_TO: layoutConstants.showClassDropdownTo,
      routes: [
        /* DASHBOARD */
        {
          id: '1',
          icon: 'dashboard',
          name: 'Dashboard',
          role: [
            TENANT_ROLES.SUPER_ADMIN,
            TENANT_ROLES.SECTION_TEACHER,
            TENANT_ROLES.SECTION_STUDENT,
            TENANT_ROLES.CAMPUS_ADMIN,
          ],
          route: { name: 'control-panel-dashboard' },
        },
        /* FEATURES Permission SETTINGS */
        {
          id: '002',
          icon: 'dashboard',
          name: 'LB_FEATURE_SETTINGS',
          basedOn: [
            SCOPE_LEVELS.ROOT_LEVEL,
            SCOPE_LEVELS.CAMPUS_LEVEL,
            SCOPE_LEVELS.CLASS_LEVEL,
            SCOPE_LEVELS.SECTION_LEVEL,
          ],
          showChilds: false,
          role: [
            TENANT_ROLES.SUPER_ADMIN,
            TENANT_ROLES.SECTION_TEACHER,
            TENANT_ROLES.SECTION_STUDENT,
            TENANT_ROLES.CAMPUS_ADMIN,
          ],
          routesName: [
            'fee-settings',
            'campus-attendance-settings',
            'syllabus-settings-permission',
            'salary-settings',
            'exam-planner-permission',
            'staff-permission',
            'roles-and-acccess-management',
            'teacher-settings',
            'syllabus-automator-permission',
            'schedule-settings',
            'lesson-planner-settings',
            'exam-settings',
            'class-permission',
            'section-permission',
            'pickup-settings-permission',
            'period-attendance-settings',
            'section-attendance-settings',
            'syllabus-settings-permission',
            'syllabus-automator-permission',
            'exam-planner-permission',
            'schedule-settings',
            'lesson-planner-settings',
            'pickup-settings-permission',
          ],
          childs: [
            {
              name: 'CLASS_PERMISSION',
              route: { name: 'class-permission' },
            },
            {
              name: 'SECTION_PERMISSION',
              route: { name: 'section-permission' },
            },
            {
              name: 'STAFF',
              route: { name: 'staff-permission' },
              basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
            },
            {
              name: 'TEACHER',
              route: { name: 'teacher-settings' },
            },
            { name: 'FEE_SETTINGS', route: { name: 'fee-settings' } },
            {
              name: 'CAMPUS_ATTENDANCE_PERMISSION',
              route: { name: 'campus-attendance-settings' },
            },
            {
              name: 'PERIOD_ATTENDANCE_PERMISSION',
              route: { name: 'period-attendance-settings' },
            },
            {
              name: 'SECTION_ATTENDANCE_PERMISSION',
              route: { name: 'section-attendance-settings' },
            },
            {
              name: 'SYLLABUS_PERMISSION_SETTINGS',
              route: { name: 'syllabus-settings-permission' },
            },
            // {
            //   name: 'TIME_TABLE_PERMISSION',
            //   route: { name: 'time-table-permission' },
            // },
            {
              name: 'SYLLABUS_AUTOMATOR_PERMISSION',
              route: { name: 'syllabus-automator-permission' },
              basedOn: ['root-level', 'campus-level'],
            },
            {
              name: 'EXAM_PLANNER_PERMISSION',
              route: { name: 'exam-planner-permission' },
            },
            {
              name: 'SCHEDULE_SETTINGS',
              route: { name: 'schedule-settings' },
            },
            {
              name: 'LESSON_PLANNER_SETTINGS',
              route: { name: 'lesson-planner-settings' },
            },
            {
              name: 'PICKUP',
              route: { name: 'pickup-settings-permission' },
            },
          ],
        },
        /* SYSTEM SETTINGS */
        {
          id: '3',
          icon: 'dashboard',
          name: 'SYSTEM_SETTINGS',
          basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
          role: [TENANT_ROLES.SUPER_ADMIN, , TENANT_ROLES.CAMPUS_ADMIN],
          showChilds: false,
          routesName: [
            'general-settings',
            'contact-settings',
            'billing-settings',
            'site-settings',
            'fee-system-settings',
          ],
          childs: [
            {
              name: 'General Settings',
              route: { name: 'general-settings' },
            },
            {
              name: 'CONTACT_SETTINGS',
              route: { name: 'contact-settings' },
            },
            {
              name: 'Billing Settings',
              route: { name: 'billing-settings' },
            },
            {
              name: 'Site Settings',
              route: { name: 'site-settings' },
            },
          ],
        },
        /* NOTIFICATION SETTINGS */
        {
          id: '4',
          icon: 'dashboard',
          name: 'NOTIFICATION_SETTINGS',
          basedOn: [
            SCOPE_LEVELS.ROOT_LEVEL,
            SCOPE_LEVELS.CAMPUS_LEVEL,
            SCOPE_LEVELS.CLASS_LEVEL,
            SCOPE_LEVELS.SECTION_LEVEL,
          ],
          role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
          showChilds: false,
          routesName: [
            'fee-notification-settings',
            'campus-attendance-notification',
            'section-attendance-notification',
            'exam-notification-settings',
            'staff-notification-settings',
            'class-notification-settings',
            'student-notification-settings',
            'guardian-notification-settings',
            'time-table-notification',
            'lesson-planner-notification',
            'period-attendance-notification',
            'syllabus-manager-notification',
            'exam-planner-notification',
            'staff-notification',
            'pickup-person-notification',
            'syllabus-automator-notification',
            'class-notification',
            'syllabus-template-notification',
            'section-notification',
            'teacher-notification-settings',
            'pickup-facility-notification',
          ],
          childs: [
            {
              name: 'CLASS',
              route: { name: 'class-notification' },
            },
            {
              name: 'SECTION',
              route: { name: 'section-notification' },
            },
            {
              name: 'STUDENT_NOTIFICATION',
              route: { name: 'student-notification-settings' },
            },
            {
              name: 'TEACHER',
              route: { name: 'teacher-notification-settings' },
            },
            {
              name: 'STAFF',
              route: { name: 'staff-notification' },
              basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
            },
            {
              name: 'PICKUP_PERSON',
              route: { name: 'pickup-person-notification' },
            },
            {
              name: 'FEE',
              route: { name: 'fee-notification-settings' },
            },
            {
              name: 'CAMPUS_ATTENDANCE_PERMISSION',
              route: { name: 'campus-attendance-notification' },
            },
            {
              name: 'SECTION_ATTENDANCE_PERMISSION',
              route: { name: 'section-attendance-notification' },
            },
            {
              name: 'PERIOD_ATTENDANCE_PERMISSION',
              route: { name: 'period-attendance-notification' },
            },
            {
              name: 'EXAM_PLANNER_PERMISSION',
              route: { name: 'exam-planner-notification' },
            },
            {
              name: 'SYLLABUS_MANAGER_PERMISSION',
              route: { name: 'syllabus-manager-notification' },
            },
            {
              name: 'TIME_TABLE_PERMISSION',
              route: { name: 'time-table-notification' },
            },
            {
              name: 'LESSON_PLANNER_SETTINGS',
              route: { name: 'lesson-planner-notification' },
            },
            {
              name: 'SYLLABUS_AUTOMATOR_PERMISSION',
              route: { name: 'syllabus-automator-notification' },
            },
            {
              name: 'PICKUP_FACILITY_PERMISSION',
              route: { name: 'pickup-facility-notification' },
            },
            {
              name: 'EXAM_NOTIFICATION',
              route: { name: 'exam-notification-settings' },
            },
            {
              name: 'STAFF_NOTIFICATION',
              route: { name: 'staff-notification-settings' },
            },
            {
              name: 'CLASS_NOTIFICATION',
              route: { name: 'class-notification-settings' },
            },
            {
              name: 'GUARDIAN_NOTIFICATION',
              route: { name: 'guardian-notification-settings' },
            },
            {
              name: 'ALL_N',
              route: { name: 'all-notifications-settings' },
            },
          ],
        },
        {
          id: '5',
          icon: 'dashboard',
          name: 'FEATURE_SETTINGS',
          basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
          role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
          showChilds: false,
          routesName: ['fee-fearture-settings', 'pickup-feature-settings'],
          childs: [
            {
              name: 'General Settings',
              route: { name: 'feature-general-settings' },
              basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
            },
            {
              name: 'FEE',
              route: { name: 'fee-fearture-settings' },
            },
            {
              name: 'PICKUP',
              basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
              route: { name: 'pickup-feature-settings' },
            },
          ],
        },
        /* Payment Options */
        {
          id: '6',
          icon: 'dashboard',
          name: 'Promotion',
          route: { name: 'promote' },
          role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
          basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
        },
        {
          id: '7',
          name: 'Features-1',
          route: { name: 'features-1' },
          role: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
        },

        {
          id: '8',
          icon: 'dashboard',
          name: 'Add Payment Method',
          role: [TENANT_ROLES.SUPER_ADMIN],
          route: { name: 'payment-methods' },
          basedOn: [SCOPE_LEVELS.ROOT_LEVEL],
        },
        {
          id: '008',
          icon: 'users',
          isRenderForcefully: this.isOwner,
          role: [],
          name: 'OWNERSHIP',
          route: { name: 'ownership' },
        },

        /* ad-ons */

        {
          id: '009',
          name: 'AD_ONS',
          icon: 'dashboard',
          basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
          role: [TENANT_ROLES.SUPER_ADMIN],
          showChilds: false,
          routesName: [
            'sms-add-ons',
            'email-add-ons',
            'email-provider-detail-page',
            'sms-provider-detail-page',
          ],
          childs: [
            {
              name: 'SMS_AD_ONS',
              route: { name: 'sms-add-ons' },
            },
            {
              name: 'EMAIL_AD_ONS',
              route: { name: 'email-add-ons' },
            },
            {
              name: 'EMAIL_AD_ONS_DETAIL',
              route: { name: 'email-provider-detail-page' },
              isDynamicRoute: true,
            },
            {
              name: 'SMS_AD_ONS_DETAIL',
              route: { name: 'sms-provider-detail-page' },
              isDynamicRoute: true,
            },
          ],
        },
      ],
    })
  },
  methods: {
    ...mapActions('layout', ['setLeftbarContent', 'modifyLeftBarDropdown']),
  },
}
</script>
